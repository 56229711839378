<template>
    <v-list two-line>
        <v-list-item v-for="(comment, index) in comments"
                     :key="index">
            <v-list-item-avatar color="#f5f5f5">
                <v-icon size="18">fal fa-comments-question</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <div class="d-flex align-center">
                    <div class="mr-3">
                        <v-list-item-title
                            class="text-wrap"
                            v-html="comment.question"
                            :class="{ 'mb-1' : comment.answer, 'mb-2' : !comment.answer}"
                        />
                        <v-list-item-subtitle class="text-wrap" v-html="comment.answer" v-if="comment.answer"/>
                        <CommentsModal v-else :comment="comment" btn-label="Beantwoorden" class="ml-auto"/>
                    </div>
                    <v-btn
                        @click="openDelete(comment)"
                        v-if="$store.getters.userIsAdmin"
                        small
                        text
                        class="ml-auto mt-6"
                        color="red"
                    >
                        <v-icon size="16">fas fa-trash-alt</v-icon>
                    </v-btn>
                </div>
                <div class="d-flex align-center mt-2">
                    <v-icon size="13" class="me-2">fas fa-user</v-icon>
                    <small>{{ comment.questionUser.name }}</small>
                </div>
                <v-divider class="mt-3"></v-divider>
            </v-list-item-content>
        </v-list-item>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-center d-block">Bevestigen</v-card-title>
                <v-card-text>
                    <div>
                        <v-card-subtitle class="text-center">Weet je zeker dat je de vraag <strong>"{{ editedItem.question }}"</strong> wilt verwijderen?</v-card-subtitle>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="closeDelete">Annuleren</v-btn>
                    <v-btn color="green darken-1" text @click="deleteComment">Ja</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-list>
</template>

<script>
import CommentsModal from "../dashboard/CommentsModal.vue";

export default {
    name: "CommentsList",
    components: {CommentsModal},
    data() {
      return {
          editedItem: false,
          dialogDelete: false,
      }
    },
    computed: {
        comments() {
            return this.$store.state.comment.comments
        }
    },
    methods: {
        loadComments() {
            this.$store.dispatch('getLatestComments')
        },
        deleteComment() {
            const currentQuestion = this.editedItem.id;
            this.$store.dispatch('deleteComment', {commentId: currentQuestion})
                .then(() => {
                    this.dialogDelete = false;
                    this.$store.dispatch('showSnackbar', {text: 'Vraag verwijderd'})
                })
        },
        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = false
            })
        },
        openDelete(item) {
            this.dialogDelete = true;
            this.editedItem = item
        }
    },
    mounted() {
        this.loadComments();
    }
}
</script>

<style scoped>
    hr{
        flex-basis: 100%;
        margin-top:20px;
    }
    .v-list-item .v-list-item__title{
        margin-top:20px;
    }
    .v-list-item__content{
        padding:0;
    }
</style>
