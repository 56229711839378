import { render, staticRenderFns } from "./MaterialOrderTable.vue?vue&type=template&id=00258dcb&scoped=true&"
import script from "./MaterialOrderTable.vue?vue&type=script&lang=js&"
export * from "./MaterialOrderTable.vue?vue&type=script&lang=js&"
import style0 from "./MaterialOrderTable.vue?vue&type=style&index=0&id=00258dcb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00258dcb",
  null
  
)

export default component.exports