<template>
    <v-simple-table>
        <template v-slot:default>
            <tbody>
            <tr
                v-for="(user, index) in timeRegistration.usersWithUnconfirmedTimeRegs"
                :key="index"
            >
                <td>{{ user.name }}</td>
            </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: "UnconfirmedUsersList",
    computed: {
        ...mapState(['timeRegistration']),
    },
    methods: {
        loadUnconfirmedUsers() {
            this.$store.dispatch('getUsersWithUnconfirmedTimeRegs')
        },
    },
    mounted() {
        this.loadUnconfirmedUsers();
    }
}
</script>

<style scoped>

</style>
