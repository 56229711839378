<template>
    <div>
        <v-skeleton-loader
            v-if="$store.state.materialOrder.loading"
            type="table-tbody"
        />
        <v-data-table
            v-else
            :headers="headers"
            :items="results"
            :items-per-page="15"
            :item-class="itemClass"
            class="elevation-0 material-table"
        >
            <template v-slot:item.articleName="{ item }">
                <div>
                    {{item.articleName}}<br/>
                    <span class="text-grey">{{item.description}}</span>
                </div>
            </template>
            <template v-slot:item.actions="{ item }">
                <div class="d-flex align-center">
                    <v-btn
                        small
                        color="secondary"
                        :text="item.confirmed !== null"
                        v-if="$store.getters.userCanOrder"
                        @click="orderConfirmed(item.id)"
                    >
                        {{ (item.confirmed) ? 'Reset' : 'Besteld' }}
                    </v-btn>
                    <v-btn
                        @click="deleteRow(item)"
                        v-if="!item.confirmed"
                        class="ml-auto"
                        color="red"
                        text
                        small
                    >
                        <v-icon size="16">fas fa-trash-alt</v-icon>
                    </v-btn>
                </div>
            </template>
            <template v-slot:item.articleNo="{ item }">
                <div v-if="item.articleNo === 'photo'" class="d-flex align-center py-2">
                    <v-img
                        :aspect-ratio="1"
                        :src="item.articlePhoto"
                        flat
                        max-width="50"
                        class="image-enlarge"
                        @click.stop="expandImage(item.articlePhoto, item.articleName)"
                        :alt="item.articleName"
                        title="Vergroten"
                    ></v-img>
                </div>
                <div v-else>
                    <span :class="{ 'twoba-item' : item.orderType === '2ba' }">{{ item.articleNo }}</span>
                </div>
            </template>
        </v-data-table>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-center d-block">Bevestigen</v-card-title>
                <v-card-text>
                    <div>
                        <v-card-subtitle class="text-center">Weet je zeker dat je <strong>{{ editedItem.articleName }}</strong> wilt verwijderen?</v-card-subtitle>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="closeDelete">Annuleren</v-btn>
                    <v-btn color="green darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {Fancybox} from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export default {
    name: "MaterialOrderTable",
    props: {
        workOrder: {type: Object, required: false},
        isDashboard: {type: Boolean, default: false, required: false},
    },
    computed: {
        headers() {
            if (this.isDashboard) {
                return [
                    {text: 'Project', value: 'project'},
                    {text: 'Aantal', value: 'quantity'},
                    {text: 'Eenheid', value: 'unit'},
                    {text: 'Artikelnummer', value: 'articleNo'},
                    {text: 'Artikelomschrijving', value: 'articleName'},
                ]
            } else {
                return [
                    {text: 'Project', value: 'project'},
                    {text: 'Werkbon', value: 'workOrder'},
                    {text: 'Aantal', value: 'quantity'},
                    {text: 'Eenheid', value: 'unit'},
                    {text: 'Artikelnummer', value: 'articleNo'},
                    {text: 'Artikelomschrijving', value: 'articleName'},
                    {text: 'Besteld door', value: 'user'},
                    {text: 'Besteld op', value: 'date'},
                    {text: 'Afgerond op', value: 'confirmed', sortable: false},
                    {text: 'Acties', value: 'actions'}
                ]
            }
        },
        results() {
            let results = this.isDashboard ? this.$store.getters.getDashboardMaterialOrders : this.$store.state.materialOrder.materialOrders

            return results.map((mo => {
                let articleNo, articleName, urlPhoto = '';

                if (mo.article !== null) {
                    articleNo = mo.article.articleNumber
                    articleName = mo.article.articleName
                } else if (mo.article2ba !== null) {
                    let article2ba = mo.article2ba.split('|')
                    articleNo = article2ba[0]
                    articleName = article2ba[1]
                } else if (mo.photo !== null) {
                    articleNo = 'photo'
                    urlPhoto = mo.urlPhoto
                    articleName = mo.photoDescription
                }

                const projectNo = mo.workOrder ? mo.workOrder.project.projectNo : ''
                const voucherNumber = mo.workOrder ? mo.workOrder.voucherNumber : ''

                return {
                    id: mo.id,
                    orderType: mo.orderType,
                    project: projectNo,
                    workOrder: voucherNumber,
                    user: mo.user.name,
                    quantity: mo.quantity,
                    unit: mo.unit,
                    articleNo: articleNo,
                    articleName: articleName,
                    description: mo.photoDescription,
                    articlePhoto: urlPhoto,
                    date: this.$luxon(mo.createdAt, "dd-MM-yyyy HH:mm"),
                    confirmed: this.$luxon(mo.confirmed, "dd-MM-yyyy HH:mm"),
                }
            }))
        },
    },
    data() {
        return {
            dialogDelete: false,
            editedItem: false,
        }
    },
    methods: {
        loadMaterialOrders() {
            this.$store.dispatch('getMaterialOrders')
        },
        orderConfirmed(materialOrderId) {
            this.$store.dispatch('setOrderConfirmed', {id: materialOrderId})
                .then(() => {
                    this.loadMaterialOrders();
                })
        },
        itemClass: function (item) {
            return item.confirmed ? 'confirmed' : 'awaiting'
        },
        expandImage(image, title) {
            new Fancybox([
                {
                    src: image,
                    caption: title
                },
            ]);
        },
        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = false
            })
        },
        deleteRow(item) {
            this.dialogDelete = true;
            this.editedItem = item
        },
        deleteItemConfirm () {
            this.dialogDelete = false;
            this.$store.dispatch('deleteMaterialOrder', {materialOrderId: this.editedItem.id})
                .then(() => {
                    this.loadMaterialOrders();
                    this.$store.dispatch('showSnackbar', {text: 'Bestelling verwijderd'})
                    this.editedItem = false
                })
        },
    },
    mounted() {
        this.loadMaterialOrders();
    }
}
</script>

<style scoped>
.text-grey {font-size:.8rem;color:#888;}
</style>
