<template>
    <v-container class="home-view">
        <v-row>
            <v-col cols="12" lg="6">
                <v-card class="mb-4">
                    <v-card-title>
                        Algemene vragen
                        <v-badge
                            v-if="getOpenCommentsCount > 0"
                            :content="getOpenCommentsCount"
                            color="primary"
                        />
                        <CommentsModal
                            btn-label="Stel een vraag"
                            class="ml-auto"
                        />
                    </v-card-title>
                    <v-card-text>
                        <CommentsList />
                    </v-card-text>
                </v-card>
                <v-card class="mb-4">
                    <v-card-title>
                        Werkbon vragen
                        <v-badge
                            v-if="getOpenQuestionsCount > 0"
                            :content="getOpenQuestionsCount"
                            color="primary"
                        />
                    </v-card-title>
                    <v-card-text>
                        <WorkOrderCommentsList />
                    </v-card-text>
                </v-card>

                <v-card>
                    <router-link :to="{name: 'WorkOrderListView'}">
                        <v-card-title v-ripple>
                            Werkbonnen <v-icon size="18" class="ml-auto" color="white">fal fa-chevron-right fa-fw</v-icon>
                        </v-card-title>
                    </router-link>
                    <v-card-text>
                        <WorkOrderList :is-dashboard="true"/>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" lg="6">
                <v-card class="mb-4">
                    <router-link :to="{name: 'TimeRegistrationView'}">
                        <v-card-title v-ripple>
                            Urenregistratie niet bevestigd <v-icon size="18" class="ml-auto" color="white">fal fa-chevron-right fa-fw</v-icon>
                        </v-card-title>
                    </router-link>
                    <v-card-text>
                        <UnconfirmedUsersList />
                    </v-card-text>
                </v-card>

                <v-card class="mb-3">
                    <router-link :to="{name: 'MaterialOrderListView'}">
                        <v-card-title v-ripple>
                            Bestellingen
                            <v-badge
                                v-if="getNumberOfUnconfirmedOrders > 0"
                                :content="getNumberOfUnconfirmedOrders"
                                color="primary"
                            /> <v-icon size="18" class="ml-auto" color="white">fal fa-chevron-right fa-fw</v-icon>
                        </v-card-title>
                    </router-link>
                    <v-card-text>
                        <MaterialOrderTable :is-dashboard="true"/>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12"><p class="text-right overline text--disabled">v{{appVersion}}</p></v-col>
        </v-row>
    </v-container>
</template>

<script>

import WorkOrderList from "@/components/workOrder/WorkOrderList";
import MaterialOrderTable from "@/components/materialOrder/MaterialOrderTable";
import WorkOrderCommentsList from "@/components/dashboard/WorkOrderCommentsList.vue";
import CommentsList from "@/components/dashboard/CommentsList.vue";
import UnconfirmedUsersList from "@/components/timeRegistration/UnconfirmedUsersList.vue";
import {mapGetters} from "vuex";
import CommentsModal from "../components/dashboard/CommentsModal.vue";

export default {
    name: 'DashboardView',
    components: {
        CommentsModal,
        UnconfirmedUsersList,
        CommentsList,
        WorkOrderCommentsList,
        MaterialOrderTable,
        WorkOrderList
    },
    data() {
        return {
            appVersion: process.env.VUE_APP_VERSION,
        }
    },
    computed: {
        ...mapGetters(['getNumberOfUnconfirmedOrders', 'getOpenQuestionsCount', 'getOpenCommentsCount'])
    },
}
</script>
