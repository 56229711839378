<template>
    <v-list two-line>
        <v-list-item v-for="(item, index) in comments"
                     :key="index"
                     :to="{name:'WorkOrderView', params: { id: item.workOrder.id } }"
        >
            <v-list-item-content>
                <div class="d-flex align-center">
                    <div class="mr-3">
                        <v-list-item-title class="text-wrap"><b>{{ item.workOrder.voucherNumber }}</b>: {{ item.question }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.answer }}</v-list-item-subtitle>
                    </div>
                    <v-btn
                        @click.prevent="openDelete(item)"
                        v-if="$store.getters.userIsAdmin && !item.workOrder.signedAt"
                        small
                        text
                        class="ml-auto mt-6"
                        color="red"
                    >
                        <v-icon size="16">fas fa-trash-alt</v-icon>
                    </v-btn>
                </div>
            </v-list-item-content>
        </v-list-item>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-center d-block">Bevestigen</v-card-title>
                <v-card-text>
                    <div>
                        <v-card-subtitle class="text-center">Weet je zeker dat je de vraag <strong>"{{ editedItem.question }}"</strong> wilt verwijderen?</v-card-subtitle>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="closeDelete">Annuleren</v-btn>
                    <v-btn color="green darken-1" text @click="deleteComment">Ja</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-list>
</template>

<script>
export default {
    name: "WorkOrderCommentsList",
    data() {
        return {
            editedItem: false,
            dialogDelete: false,
        }
    },
    computed: {
        comments() {
            return this.$store.state.workOrderComment.dashboardComments
        }
    },
    methods: {
        loadWorkOrderComments() {
            this.$store.dispatch('getLatestWorkOrderComments')
        },
        deleteComment() {
            const currentQuestion = this.editedItem.id;
            this.$store.dispatch('deleteWorkOrderComment', {workOrderCommentId: currentQuestion})
                .then(() => {
                    this.dialogDelete = false;
                    this.loadWorkOrderComments()
                    this.$store.dispatch('showSnackbar', {text: 'Vraag verwijderd'})
                })
        },
        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = false
            })
        },
        openDelete(item) {
            this.dialogDelete = true;
            this.editedItem = item
        }
    },
    mounted() {
        this.loadWorkOrderComments();
    }
}
</script>

<style scoped>

</style>
